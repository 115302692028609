/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateCommonDataDTO = {
    name: string;
    status: CreateCommonDataDTO.status;
};

export namespace CreateCommonDataDTO {

    export enum status {
        ACTIVE = 'active',
        HIDDEN = 'hidden',
    }


}

