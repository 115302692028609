/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PayoutDetailsPaypalResponseDTO = {
    type: PayoutDetailsPaypalResponseDTO.type;
    paypalEmail: string;
};

export namespace PayoutDetailsPaypalResponseDTO {

    export enum type {
        PAYPAL = 'paypal',
    }


}

