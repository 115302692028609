/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateLuxuryServiceRequestDTO = {
    status?: UpdateLuxuryServiceRequestDTO.status;
};

export namespace UpdateLuxuryServiceRequestDTO {

    export enum status {
        ACTIVE = 'active',
        CANCELED = 'canceled',
        DONE = 'done',
    }


}

