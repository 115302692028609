/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AdminIncludeStatusBookingDTO = {
    /**
     * Send the _id
     */
    userId: string;
    status: AdminIncludeStatusBookingDTO.status;
};

export namespace AdminIncludeStatusBookingDTO {

    export enum status {
        PENDING = 'pending',
        WAITING_PAYMENT = 'waitingPayment',
        APPROVED = 'approved',
        DECLINED = 'declined',
        CHECK_IN = 'checkIn',
        INCOMPLETE = 'incomplete',
        CANCELED = 'canceled',
        DONE = 'done',
        PAID = 'paid',
    }


}

