/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AuthStatusErrorDataResponseDTO = {
    status: AuthStatusErrorDataResponseDTO.status;
};

export namespace AuthStatusErrorDataResponseDTO {

    export enum status {
        BLOCKED = 'blocked',
        DEACTIVATED = 'deactivated',
        DELETED = 'deleted',
    }


}

