/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ChangeUserBoostTopDTO = {
    /**
     * Period
     */
    period?: ChangeUserBoostTopDTO.period;
    /**
     * Send the _id
     */
    boostCity?: string;
};

export namespace ChangeUserBoostTopDTO {

    /**
     * Period
     */
    export enum period {
        _0 = '0',
        _24H = '24h',
        _48H = '48h',
        _72H = '72h',
        _7D = '7d',
    }


}

