/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AddBookingChatMessageDTO = {
    /**
     * Send the _id
     */
    room: string;
    text: string;
    /**
     * The path of the downloaded file
     */
    filePath?: string;
    fileOriginalname?: string;
    /**
     * Required if filePath is specified
     */
    fileType?: AddBookingChatMessageDTO.fileType;
};

export namespace AddBookingChatMessageDTO {

    /**
     * Required if filePath is specified
     */
    export enum fileType {
        IMAGE = 'image',
        DOCUMENT = 'document',
    }


}

