/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateLuxuryServiceDTO = {
    name?: string;
    category?: UpdateLuxuryServiceDTO.category;
    generalCategory?: UpdateLuxuryServiceDTO.generalCategory;
    serviceLevels?: Array<string>;
    description?: string;
    email?: string;
    emailBcc?: string;
    phone?: string;
    discount?: number;
    isDiscountWithMembership?: boolean;
    status?: UpdateLuxuryServiceDTO.status;
    uploadPhotos?: Array<Blob>;
    uploadLogo?: Blob;
    deletePhotos?: Array<string>;
    /**
     * Update the order of photos
     */
    photos?: Array<string>;
};

export namespace UpdateLuxuryServiceDTO {

    export enum category {
        RESTAURANT = 'restaurant',
        VILLA = 'villa',
        JETS = 'jets',
        YACHT = 'yacht',
        CARS = 'cars',
    }

    export enum generalCategory {
        TRAVEL = 'Travel',
        ACCOMMODATION = 'Accommodation',
        TRANSPORTATION = 'Transportation',
        DINING_EVENTS = 'Dining & Events',
    }

    export enum status {
        ACTIVE = 'active',
        HIDDEN = 'hidden',
    }


}

