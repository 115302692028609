/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type VenueTypeDataResponseDTO = {
    _id: string;
    name: string;
    /**
     * Available for admin
     */
    status?: VenueTypeDataResponseDTO.status;
    /**
     * Available for admin
     */
    logo?: string;
    /**
     * Available for admin
     */
    photos?: Array<string>;
    /**
     * Available for admin
     */
    createdAt?: string;
    /**
     * Available for admin
     */
    updatedAt?: string;
};

export namespace VenueTypeDataResponseDTO {

    /**
     * Available for admin
     */
    export enum status {
        ACTIVE = 'active',
        HIDDEN = 'hidden',
    }


}

