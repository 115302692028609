/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CountryPopulateResponseDTO } from './CountryPopulateResponseDTO';

export type CharityResponseDTO = {
    _id: string;
    name: string;
    country: CountryPopulateResponseDTO;
    category: string;
    description: string;
    logo: string;
    photos: Array<string>;
    donation: number;
    status: CharityResponseDTO.status;
    createdAt: string;
    updatedAt: string;
};

export namespace CharityResponseDTO {

    export enum status {
        ACTIVE = 'active',
        HIDDEN = 'hidden',
    }


}

