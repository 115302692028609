/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PayoutDetailsStripeBankResponseDTO = {
    type: PayoutDetailsStripeBankResponseDTO.type;
    bankName: string;
    country: string;
    currency: string;
    last4: string;
};

export namespace PayoutDetailsStripeBankResponseDTO {

    export enum type {
        STRIPE_BANK = 'stripeBank',
    }


}

