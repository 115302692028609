/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PaymentSubscriptionPriceResponseDTO = {
    id: string;
    title: string;
    amount: number;
    currency: string;
    interval: PaymentSubscriptionPriceResponseDTO.interval;
};

export namespace PaymentSubscriptionPriceResponseDTO {

    export enum interval {
        DAY = 'day',
        WEEK = 'week',
        MONTH = 'month',
        YEAR = 'year',
    }


}

