/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AdminCreateTransactionDTO = {
    /**
     * Send the _id
     */
    owner: string;
    /**
     * Send the _id
     */
    userSource?: string;
    /**
     * Send the _id
     */
    booking?: string;
    resourceType?: AdminCreateTransactionDTO.resourceType;
    /**
     * Send the _id
     */
    resource?: string;
    /**
     * Send the _id
     */
    charity?: string;
    /**
     * Send the _id
     */
    city: string;
    paid: number;
    amount: number;
    additionalAmount: number;
    commission: number;
    charityDonation?: number;
    title: string;
    description: string;
    type: AdminCreateTransactionDTO.type;
    category: AdminCreateTransactionDTO.category;
    movementFunds: AdminCreateTransactionDTO.movementFunds;
    createdAt: string;
};

export namespace AdminCreateTransactionDTO {

    export enum resourceType {
        PRODUCT = 'Product',
        BOOKING = 'Booking',
    }

    export enum type {
        PAYMENT = 'payment',
        REFUND = 'refund',
    }

    export enum category {
        PRODUCT_PAYMENT = 'productPayment',
        CREATE_BOOKING = 'createBooking',
        BOOKING_PAYMENT_TALENT = 'bookingPaymentTalent',
        BOOKING_UPDATE_PAYMENT_TALENTS = 'bookingUpdatePaymentTalents',
        BOOKING_PAYMENT_PARTICIPATION = 'bookingPaymentParticipation',
        BALANCE_REPLENISHMENT = 'balanceReplenishment',
        BOOKING_APPROVE_CANCEL = 'bookingApproveCancel',
        BOOKING_PAID_TALENT = 'bookingPaidTalent',
        BOOKING_CHARITY_DONATION = 'bookingCharityDonation',
        BOOKING_PAID_PARTICIPATION = 'bookingPaidParticipation',
        MEMBERSHIP_PAYMENT = 'membershipPayment',
    }

    export enum movementFunds {
        REPLENISHMENT = 'replenishment',
        WRITE_DOWNS = 'write-downs',
    }


}

