/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PhylloWorkPlatformResponseDTO = {
    name: PhylloWorkPlatformResponseDTO.name;
    phylloPlatformId: string;
};

export namespace PhylloWorkPlatformResponseDTO {

    export enum name {
        TIK_TOK = 'TikTok',
        INSTAGRAM = 'Instagram',
        YOU_TUBE = 'YouTube',
        X = 'X',
        TWITCH = 'Twitch',
        FACEBOOK = 'Facebook',
        SUBSTACK = 'Substack',
        INSTAGRAM_LITE = 'Instagram Lite',
        AD_SENSE = 'AdSense',
        SPOTIFY = 'Spotify',
        LINKED_IN = 'LinkedIn',
        BEEHIIV = 'Beehiiv',
    }


}

