/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdatePushNotificationDTO = {
    userRole?: UpdatePushNotificationDTO.userRole;
    /**
     * Send the _id
     */
    cities?: Array<string>;
    /**
     * Send the _id
     */
    recipients?: Array<string>;
    segmentation?: UpdatePushNotificationDTO.segmentation;
    title?: string;
    text?: string;
    sendDate?: string;
};

export namespace UpdatePushNotificationDTO {

    export enum userRole {
        CLIENT = 'client',
        TALENT = 'talent',
        ADMIN = 'admin',
    }

    export enum segmentation {
        ALL = 'all',
        NEW = 'new',
        NOT_ACTIVE = 'notActive',
        NO_BOOKING = 'noBooking',
        ACTIVE = 'active',
        FREE = 'Free',
        ELITE = 'Elite',
        PREMIUM = 'Premium',
        UPDATED = 'updated',
        BOOSTED = 'boosted',
    }


}

