/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CountryResponseDTO } from '../models/CountryResponseDTO';
import type { CreateCountryDTO } from '../models/CreateCountryDTO';
import type { PageCountryResponseDTO } from '../models/PageCountryResponseDTO';
import type { UpdateCountryDTO } from '../models/UpdateCountryDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CountryService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Country creation
     * @returns any Country successfully created
     * @throws ApiError
     */
    public createCountry({
        requestBody,
    }: {
        requestBody: CreateCountryDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/country',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
            },
        });
    }

    /**
     * Update country name
     * @returns void
     * @throws ApiError
     */
    public updateCountry({
        id,
        requestBody,
    }: {
        /**
         * Id
         */
        id: string,
        requestBody: UpdateCountryDTO,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/country/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
                404: `Country not found`,
            },
        });
    }

    /**
     * Delete country
     * @returns void
     * @throws ApiError
     */
    public deleteCountry({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/country/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
                404: `Country not found`,
            },
        });
    }

    /**
     * Country
     * @returns CountryResponseDTO Country
     * @throws ApiError
     */
    public getCountry({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<CountryResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/country/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Wrong authorization data`,
                404: `Country not found`,
            },
        });
    }

    /**
     * Country list
     * @returns PageCountryResponseDTO List of country
     * @throws ApiError
     */
    public getCountryList({
        search,
        status,
        page = 1,
        limit = 10,
        isCount,
    }: {
        /**
         * Used to filter data by name
         */
        search?: string,
        /**
         * Can be used by the admin.
         */
        status?: 'active' | 'hidden',
        page?: number,
        /**
         * If 0 is specified, we will return the data without a limit
         */
        limit?: number,
        /**
         * If it actively returns the quantity.
         */
        isCount?: boolean,
    }): CancelablePromise<PageCountryResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/country/list',
            query: {
                'search': search,
                'status': status,
                'page': page,
                'limit': limit,
                'isCount': isCount,
            },
        });
    }

    /**
     * Country Count
     * @returns number Country Count
     * @throws ApiError
     */
    public getCountryCount({
        search,
        status,
    }: {
        /**
         * Used to filter data by name
         */
        search?: string,
        /**
         * Can be used by the admin.
         */
        status?: 'active' | 'hidden',
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/country/count',
            query: {
                'search': search,
                'status': status,
            },
        });
    }

}
