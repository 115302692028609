/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BookingChatUploadFileResponseDTO = {
    filePath: string;
    fileOriginalname: string;
    fileType: BookingChatUploadFileResponseDTO.fileType;
};

export namespace BookingChatUploadFileResponseDTO {

    export enum fileType {
        IMAGE = 'image',
        DOCUMENT = 'document',
    }


}

