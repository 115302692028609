/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreatePayoutDTO = {
    type: CreatePayoutDTO.type;
    amount: number;
    /**
     * Required for paypal
     */
    paypalEmail?: string;
};

export namespace CreatePayoutDTO {

    export enum type {
        STRIPE = 'stripe',
        PAYPAL = 'paypal',
    }


}

