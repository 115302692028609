/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LuxuryServiceResponseDTO = {
    _id: string;
    name: string;
    category: LuxuryServiceResponseDTO.category;
    generalCategory: LuxuryServiceResponseDTO.generalCategory;
    serviceLevels: Array<string>;
    description: string;
    email: string;
    emailBcc: string;
    phone: string;
    discount: number;
    requestCount: number;
    isDiscountWithMembership: boolean;
    logo: string;
    photos: Array<string>;
    status: LuxuryServiceResponseDTO.status;
    createdAt: string;
    updatedAt: string;
};

export namespace LuxuryServiceResponseDTO {

    export enum category {
        RESTAURANT = 'restaurant',
        VILLA = 'villa',
        JETS = 'jets',
        YACHT = 'yacht',
        CARS = 'cars',
    }

    export enum generalCategory {
        TRAVEL = 'Travel',
        ACCOMMODATION = 'Accommodation',
        TRANSPORTATION = 'Transportation',
        DINING_EVENTS = 'Dining & Events',
    }

    export enum status {
        ACTIVE = 'active',
        HIDDEN = 'hidden',
    }


}

