/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserDocumentDataResponseDTO = {
    status: UserDocumentDataResponseDTO.status;
    path?: string;
    uploadAt: string;
};

export namespace UserDocumentDataResponseDTO {

    export enum status {
        PENDING = 'pending',
        APPROVED = 'approved',
        REVIEW = 'review',
        DECLINED = 'declined',
    }


}

