/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CityPopulateResponseDTO } from './CityPopulateResponseDTO';
import type { VenueBookingInstructionsResponseDTO } from './VenueBookingInstructionsResponseDTO';
import type { VenueGeometryDTO } from './VenueGeometryDTO';
import type { VenueHoursOperationsResponseDTO } from './VenueHoursOperationsResponseDTO';
import type { VenueMinDataResponseDTO } from './VenueMinDataResponseDTO';

export type BookingVenueResponseDTO = {
    _id: string;
    name: string;
    /**
     * If type = travel then this value may not be.
     */
    phone?: string;
    /**
     * If type = travel then this value may not be.
     */
    email?: string;
    description: string;
    /**
     * If type = travel then this value may not be.
     */
    address?: string;
    /**
     * If type = travel then this value may not be.
     */
    city?: CityPopulateResponseDTO;
    /**
     * If type = travel then this value may not be.
     */
    priceRating?: BookingVenueResponseDTO.priceRating;
    logo: string;
    photos: Array<string>;
    /**
     * If type = travel then this value may not be.
     */
    type?: string;
    experienceType: BookingVenueResponseDTO.experienceType;
    /**
     * If type = travel then this value may not be.
     */
    location?: VenueGeometryDTO;
    /**
     * If type = travel then this value may not be.
     */
    kitchens?: Array<string>;
    /**
     * If type = travel then this value may not be.
     */
    diningStyles?: Array<string>;
    /**
     * If type = travel then this value may not be.
     */
    dressCodes?: Array<string>;
    /**
     * If type = travel then this value may not be.
     */
    hoursOperations?: VenueHoursOperationsResponseDTO;
    venueMinData: VenueMinDataResponseDTO;
    bookingInstructions: VenueBookingInstructionsResponseDTO;
};

export namespace BookingVenueResponseDTO {

    /**
     * If type = travel then this value may not be.
     */
    export enum priceRating {
        LOW = 'low',
        MEDIUM = 'medium',
        HIGH = 'high',
        VERY_HIGH = 'veryHigh',
    }

    export enum experienceType {
        EVENT = 'event',
        TRAVEL = 'travel',
    }


}

