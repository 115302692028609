/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageSendEmailListResponseDTO } from '../models/PageSendEmailListResponseDTO';
import type { SendEmailResponseDTO } from '../models/SendEmailResponseDTO';
import type { SendEmailTemplateDTO } from '../models/SendEmailTemplateDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EmailService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Send Email Template
     * @returns any Email sended
     * @throws ApiError
     */
    public sendEmailTemplate({
        requestBody,
    }: {
        requestBody: SendEmailTemplateDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/email/template',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or marketing.accessEmail can execute the request.`,
            },
        });
    }

    /**
     * Delete Send email
     * @returns any Send email successfully deleted
     * @throws ApiError
     */
    public deleteSendEmail({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/email/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or isDelete can execute the request.`,
                404: `Send email not found`,
            },
        });
    }

    /**
     * Send email
     * @returns SendEmailResponseDTO Send email
     * @throws ApiError
     */
    public getSendEmail({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<SendEmailResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/email/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or marketing.accessEmail can execute the request.`,
            },
        });
    }

    /**
     * Send email list
     * @returns PageSendEmailListResponseDTO List of Send emails
     * @throws ApiError
     */
    public getSendEmailList({
        search,
        userRole,
        userStatus,
        segmentation,
        cities,
        page = 1,
        limit = 10,
        pageFilter,
        isCount,
    }: {
        /**
         * Used to filter data by title
         */
        search?: string,
        userRole?: 'client' | 'talent' | 'admin',
        userStatus?: 'pending' | 'junk' | 'approved' | 'declined' | 'blocked' | 'deactivated' | 'deleted',
        segmentation?: 'all' | 'new' | 'notActive' | 'noBooking' | 'active' | 'Free' | 'Elite' | 'Premium' | 'updated' | 'boosted',
        /**
         * Send the _id
         */
        cities?: Array<string>,
        page?: number,
        /**
         * If 0 is specified, we will return the data without a limit
         */
        limit?: number,
        /**
         * Used to filter the received data. We get data where the createdAt is less than the specified date. If possible, use this to get the following data instead of the "page" parameter as it works faster.
         */
        pageFilter?: string,
        /**
         * If it actively returns the quantity.
         */
        isCount?: boolean,
    }): CancelablePromise<PageSendEmailListResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/email/list',
            query: {
                'search': search,
                'userRole': userRole,
                'userStatus': userStatus,
                'segmentation': segmentation,
                'cities': cities,
                'page': page,
                'limit': limit,
                'pageFilter': pageFilter,
                'isCount': isCount,
            },
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or marketing.accessEmail can execute the request.`,
            },
        });
    }

    /**
     * Send email Count
     * @returns number Send email Count
     * @throws ApiError
     */
    public getSendEmailCount({
        search,
        userRole,
        userStatus,
        segmentation,
        cities,
    }: {
        /**
         * Used to filter data by title
         */
        search?: string,
        userRole?: 'client' | 'talent' | 'admin',
        userStatus?: 'pending' | 'junk' | 'approved' | 'declined' | 'blocked' | 'deactivated' | 'deleted',
        segmentation?: 'all' | 'new' | 'notActive' | 'noBooking' | 'active' | 'Free' | 'Elite' | 'Premium' | 'updated' | 'boosted',
        /**
         * Send the _id
         */
        cities?: Array<string>,
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/email/count',
            query: {
                'search': search,
                'userRole': userRole,
                'userStatus': userStatus,
                'segmentation': segmentation,
                'cities': cities,
            },
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or marketing.accessEmail can execute the request.`,
            },
        });
    }

}
