/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateSystemMessageDTO = {
    file?: Blob;
    userRole?: UpdateSystemMessageDTO.userRole;
    /**
     * Send the _id
     */
    cities?: Array<string>;
    segmentation?: UpdateSystemMessageDTO.segmentation;
    title?: string;
    text?: string;
    status?: UpdateSystemMessageDTO.status;
    startDate?: string;
    endDate?: string;
    isDeleteImage?: boolean;
};

export namespace UpdateSystemMessageDTO {

    export enum userRole {
        CLIENT = 'client',
        TALENT = 'talent',
        ADMIN = 'admin',
    }

    export enum segmentation {
        ALL = 'all',
        NEW = 'new',
        NOT_ACTIVE = 'notActive',
        NO_BOOKING = 'noBooking',
        ACTIVE = 'active',
        FREE = 'Free',
        ELITE = 'Elite',
        PREMIUM = 'Premium',
        UPDATED = 'updated',
        BOOSTED = 'boosted',
    }

    export enum status {
        ACTIVE = 'active',
        HIDDEN = 'hidden',
    }


}

