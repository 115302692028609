/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BookingConfirmPaymentTalentDTO = {
    /**
     * Send the _id
     */
    bookingId: string;
    /**
     * Send the _id
     */
    userId: string;
    type: BookingConfirmPaymentTalentDTO.type;
    isPaymentBalance: boolean;
};

export namespace BookingConfirmPaymentTalentDTO {

    export enum type {
        INVITED = 'invited',
        INCLUDED_TALENTS = 'includedTalents',
    }


}

