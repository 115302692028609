/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AdminUserListResponseDTO } from './AdminUserListResponseDTO';
import type { CityPopulateResponseDTO } from './CityPopulateResponseDTO';

export type PushNotificationResponseDTO = {
    _id: string;
    userRole: PushNotificationResponseDTO.userRole;
    cities: Array<CityPopulateResponseDTO>;
    recipients: Array<AdminUserListResponseDTO>;
    segmentation: PushNotificationResponseDTO.segmentation;
    title: string;
    text: string;
    sendDate: string;
    sendCount: number;
    createdAt: string;
    updatedAt: string;
};

export namespace PushNotificationResponseDTO {

    export enum userRole {
        CLIENT = 'client',
        TALENT = 'talent',
        ADMIN = 'admin',
    }

    export enum segmentation {
        ALL = 'all',
        NEW = 'new',
        NOT_ACTIVE = 'notActive',
        NO_BOOKING = 'noBooking',
        ACTIVE = 'active',
        FREE = 'Free',
        ELITE = 'Elite',
        PREMIUM = 'Premium',
        UPDATED = 'updated',
        BOOSTED = 'boosted',
    }


}

