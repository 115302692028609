/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminAcceptW9DTO } from '../models/AdminAcceptW9DTO';
import type { AdminDeclineW9DTO } from '../models/AdminDeclineW9DTO';
import type { AdminUploadW9DTO } from '../models/AdminUploadW9DTO';
import type { StartVerificationIdentityDTO } from '../models/StartVerificationIdentityDTO';
import type { StartVerificationIdentityResponseDTO } from '../models/StartVerificationIdentityResponseDTO';
import type { UploadFileDTO } from '../models/UploadFileDTO';
import type { UserDocumentsResponseDTO } from '../models/UserDocumentsResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserDocumentService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Start Verification Identity
     * @returns StartVerificationIdentityResponseDTO Data needed for the SDK
     * @throws ApiError
     */
    public startVerificationIdentity({
        requestBody,
    }: {
        requestBody: StartVerificationIdentityDTO,
    }): CancelablePromise<StartVerificationIdentityResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/document/start-verification-identity',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
                404: `User not found`,
            },
        });
    }

    /**
     * Add w-9 form
     * @returns UserDocumentsResponseDTO W-9 form added
     * @throws ApiError
     */
    public uploadW9({
        formData,
    }: {
        formData: UploadFileDTO,
    }): CancelablePromise<UserDocumentsResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/document/upload-w9',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Invalid authorization data`,
                404: `User not found`,
            },
        });
    }

    /**
     * Admin Add w-9 form
     * @returns UserDocumentsResponseDTO W-9 form added
     * @throws ApiError
     */
    public adminUploadW9({
        id,
        formData,
    }: {
        /**
         * Id
         */
        id: string,
        formData: AdminUploadW9DTO,
    }): CancelablePromise<UserDocumentsResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/document/{id}/upload-w9',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right is Super Admin or [client.changeAccount, talent.changeAccount] can execute the request.`,
                404: `User not found`,
            },
        });
    }

    /**
     * Admin Delete w-9 form
     * @returns UserDocumentsResponseDTO W-9 form deleted
     * @throws ApiError
     */
    public adminDeleteW9({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<UserDocumentsResponseDTO> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/user/document/{id}/upload-w9',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right is Super Admin or [client.changeAccount, talent.changeAccount] can execute the request.`,
                404: `User not found`,
            },
        });
    }

    /**
     * Approve user w-9 form status
     * @returns any
     * @throws ApiError
     */
    public approveUserW9FormStatus({
        requestBody,
    }: {
        requestBody: AdminAcceptW9DTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user/document/w9/approve',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `You cannot update your status`,
                401: `Invalid authorization data`,
                403: `Only an admin with the right is Super Admin or [client.changeAccount, talent.changeAccount] can execute the request.`,
                404: `User not found`,
            },
        });
    }

    /**
     * Decline user w-9 form status
     * @returns any
     * @throws ApiError
     */
    public declineUserW9FormStatus({
        requestBody,
    }: {
        requestBody: AdminDeclineW9DTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user/document/w9/decline',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `You cannot update your status`,
                401: `Invalid authorization data`,
                403: `Only an admin with the right is Super Admin or [client.changeAccount, talent.changeAccount] can execute the request.`,
                404: `User not found`,
            },
        });
    }

}
