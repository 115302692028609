/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateCharityDTO = {
    name?: string;
    /**
     * Send the _id
     */
    country?: string;
    category?: string;
    description?: string;
    status?: UpdateCharityDTO.status;
    uploadPhotos?: Array<Blob>;
    uploadLogo?: Blob;
    deletePhotos?: Array<string>;
    /**
     * Update the order of photos
     */
    photos?: Array<string>;
};

export namespace UpdateCharityDTO {

    export enum status {
        ACTIVE = 'active',
        HIDDEN = 'hidden',
    }


}

