/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AdminUserVerificationDTO = {
    /**
     * Send the _id
     */
    id: string;
    status: AdminUserVerificationDTO.status;
};

export namespace AdminUserVerificationDTO {

    export enum status {
        PENDING = 'pending',
        JUNK = 'junk',
        APPROVED = 'approved',
        DECLINED = 'declined',
        BLOCKED = 'blocked',
        DEACTIVATED = 'deactivated',
        DELETED = 'deleted',
    }


}

