/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CityPopulateResponseDTO } from './CityPopulateResponseDTO';

export type LikeUserResponseDTO = {
    _id: string;
    firstName: string;
    lastName: string;
    city: CityPopulateResponseDTO;
    avatar: string;
    rating: number;
    role: LikeUserResponseDTO.role;
    status: LikeUserResponseDTO.status;
};

export namespace LikeUserResponseDTO {

    export enum role {
        CLIENT = 'client',
        TALENT = 'talent',
        ADMIN = 'admin',
    }

    export enum status {
        PENDING = 'pending',
        JUNK = 'junk',
        APPROVED = 'approved',
        DECLINED = 'declined',
        BLOCKED = 'blocked',
        DEACTIVATED = 'deactivated',
        DELETED = 'deleted',
    }


}

