/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CharityResponseDTO } from '../models/CharityResponseDTO';
import type { CreateCharityDTO } from '../models/CreateCharityDTO';
import type { PageCharityResponseDTO } from '../models/PageCharityResponseDTO';
import type { UpdateCharityDTO } from '../models/UpdateCharityDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CharityService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Charity creation
     * @returns any Charity successfully created
     * @throws ApiError
     */
    public createCharity({
        formData,
    }: {
        formData: CreateCharityDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/charity',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin or charity.addEditCharity can execute the request.`,
            },
        });
    }

    /**
     * Update Charity name
     * @returns void
     * @throws ApiError
     */
    public updateCharity({
        id,
        formData,
    }: {
        /**
         * Id
         */
        id: string,
        formData: UpdateCharityDTO,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/charity/{id}',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin or charity.addEditCharity can execute the request.`,
                404: `Charity not found`,
            },
        });
    }

    /**
     * Delete Charity
     * @returns void
     * @throws ApiError
     */
    public deleteCharity({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/charity/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin or isDelete can execute the request.`,
                404: `Charity not found`,
            },
        });
    }

    /**
     * Get Charity
     * @returns CharityResponseDTO Charity
     * @throws ApiError
     */
    public getCharity({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<CharityResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/charity/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Wrong authorization data`,
                404: `Charity not found`,
            },
        });
    }

    /**
     * Charity list
     * @returns PageCharityResponseDTO List of charities
     * @throws ApiError
     */
    public getCharityList({
        search,
        status,
        page = 1,
        limit = 10,
        isCount,
    }: {
        /**
         * Used to filter by name
         */
        search?: string,
        /**
         * Can be used by the admin.
         */
        status?: 'active' | 'hidden',
        page?: number,
        /**
         * If 0 is specified, we will return the data without a limit
         */
        limit?: number,
        /**
         * If it actively returns the quantity.
         */
        isCount?: boolean,
    }): CancelablePromise<PageCharityResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/charity/list',
            query: {
                'search': search,
                'status': status,
                'page': page,
                'limit': limit,
                'isCount': isCount,
            },
            errors: {
                401: `Wrong authorization data`,
            },
        });
    }

    /**
     * Get Random Charity
     * @returns CharityResponseDTO Random Charity
     * @throws ApiError
     */
    public getRandomCharity(): CancelablePromise<CharityResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/charity/random',
            errors: {
                401: `Wrong authorization data`,
            },
        });
    }

    /**
     * Charity Count
     * @returns number Charity Count
     * @throws ApiError
     */
    public getCharityCount({
        search,
        status,
    }: {
        /**
         * Used to filter by name
         */
        search?: string,
        /**
         * Can be used by the admin.
         */
        status?: 'active' | 'hidden',
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/charity/count',
            query: {
                'search': search,
                'status': status,
            },
            errors: {
                401: `Wrong authorization data`,
            },
        });
    }

}
