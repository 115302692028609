/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BookingChatRoomPopulateResponseDTO } from './BookingChatRoomPopulateResponseDTO';
import type { BookingUserResponseDTO } from './BookingUserResponseDTO';
import type { BookingVenueResponseDTO } from './BookingVenueResponseDTO';
import type { CityPopulateResponseDTO } from './CityPopulateResponseDTO';
import type { CountryPopulateResponseDTO } from './CountryPopulateResponseDTO';
import type { UserListResponseDTO } from './UserListResponseDTO';

export type BookingListResponseDTO = {
    _id: string;
    /**
     * When Booking by id is received, there will be an object with data instead of a string.Also, when you receive the Booking list for the admin role, there will be an object with data.
     */
    owner: (string | UserListResponseDTO);
    name: string;
    /**
     * There is if "type = travel"
     */
    city?: CityPopulateResponseDTO;
    eventType: BookingListResponseDTO.eventType;
    type: BookingListResponseDTO.type;
    visibility: BookingListResponseDTO.visibility;
    venue: BookingVenueResponseDTO;
    /**
     * There is if "type = travel".
     */
    cityTravel?: string;
    /**
     * There is if "type = travel".
     */
    countryTravel?: CountryPopulateResponseDTO;
    photos: Array<string>;
    countTalent: number;
    rateTalent: number;
    /**
     * There is if "eventType = entertainment and visibility = public"
     */
    countClient?: number;
    /**
     * There is if "eventType = entertainment and visibility = public"
     */
    rateClient?: number;
    /**
     * There is if "eventType = matchmaking"
     */
    isCharityDonation?: boolean;
    approvedTalentCount: number;
    approvedClientCount: number;
    isInvite: boolean;
    isLike: boolean;
    /**
     * Status what action is needed from the user
     */
    isAction?: boolean;
    includedTalents: Array<BookingUserResponseDTO>;
    /**
     * Data of 1 talent at the request of isFirstTalentInfo
     */
    includedTalent?: BookingUserResponseDTO;
    /**
     * There is if "eventType = entertainment and visibility = public"
     */
    includedClients?: Array<BookingUserResponseDTO>;
    invited: Array<BookingUserResponseDTO>;
    status: BookingListResponseDTO.status;
    /**
     * If the value of chatRoom._id is not empty, then the chat is available to the user and using this value it will be possible to receive messages.
     */
    chatRoom: BookingChatRoomPopulateResponseDTO;
    totalPayments: number;
    startDate: string;
    endDate: string;
    createdAt: string;
    updatedAt: string;
    /**
     * Available to the admin when viewing the bookings of a certain user
     */
    userRating: number;
};

export namespace BookingListResponseDTO {

    export enum eventType {
        ENTERTAINMENT = 'entertainment',
        MATCHMAKING = 'matchmaking',
    }

    export enum type {
        EVENT = 'event',
        TRAVEL = 'travel',
    }

    export enum visibility {
        PUBLIC = 'public',
        PRIVATE = 'private',
    }

    export enum status {
        ACTIVE = 'active',
        CANCELED = 'canceled',
        DONE = 'done',
        PAID = 'paid',
    }


}

