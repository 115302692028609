/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SignupDTO = {
    role: SignupDTO.role;
    phone: string;
    /**
     * referral code
     */
    referrerCode?: string;
    firstName: string;
    lastName: string;
    email: string;
    gender: SignupDTO.gender;
    /**
     * Send the _id
     */
    city: string;
    /**
     * Required for the talent role
     */
    birthDate?: string;
    /**
     * Required for the talent role
     */
    nicknameInstagram?: string;
    /**
     * Required for the talent role
     */
    height?: number;
    /**
     * Required for the talent role
     */
    dressSize?: string;
    /**
     * Required for the talent role
     */
    hairColor?: string;
    eyeColor?: string;
};

export namespace SignupDTO {

    export enum role {
        CLIENT = 'client',
        TALENT = 'talent',
    }

    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
    }


}

