/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateCountryDTO = {
    name: string;
    flag: string;
    code?: string;
    dialCode?: string;
    region?: CreateCountryDTO.region;
    status: CreateCountryDTO.status;
};

export namespace CreateCountryDTO {

    export enum region {
        USA = 'usa',
        EUROPE = 'europe',
        OTHER = 'other',
    }

    export enum status {
        ACTIVE = 'active',
        HIDDEN = 'hidden',
    }


}

