/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { VenueMinDataDTO } from './VenueMinDataDTO';

export type UpdateCityDTO = {
    file?: Blob;
    /**
     * Send the _id
     */
    country?: string;
    name?: string;
    timezone?: string;
    status?: UpdateCityDTO.status;
    venueDefault?: VenueMinDataDTO;
};

export namespace UpdateCityDTO {

    export enum status {
        ACTIVE = 'active',
        HIDDEN = 'hidden',
    }


}

