/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CityPopulateResponseDTO } from './CityPopulateResponseDTO';
import type { UserAdditionalFeaturesDTO } from './UserAdditionalFeaturesDTO';
import type { UserAdminRightsResponseDTO } from './UserAdminRightsResponseDTO';
import type { UserAmbassadorDTO } from './UserAmbassadorDTO';
import type { UserDocumentsResponseDTO } from './UserDocumentsResponseDTO';
import type { UserEventPreferencesResponseDTO } from './UserEventPreferencesResponseDTO';
import type { UserListResponseDTO } from './UserListResponseDTO';
import type { UserMyProfileSubscriptionResponseDTO } from './UserMyProfileSubscriptionResponseDTO';
import type { UserNotificationsResponseDTO } from './UserNotificationsResponseDTO';
import type { UserPopularityResponseDTO } from './UserPopularityResponseDTO';
import type { UserTransactionStatsResponseDTO } from './UserTransactionStatsResponseDTO';

export type AdminProfileResponseDTO = {
    _id: string;
    phone: string;
    email: string;
    firstName: string;
    lastName: string;
    legalName: string;
    city: CityPopulateResponseDTO;
    avatar: string;
    photos: Array<string>;
    video: string;
    languages: Array<string>;
    interests: Array<string>;
    bio: string;
    reviewSum: number;
    reviewCount: number;
    rating: number;
    gender: AdminProfileResponseDTO.gender;
    role: AdminProfileResponseDTO.role;
    status: AdminProfileResponseDTO.status;
    /**
     * There is a role client
     */
    subscriptionTitle?: string;
    /**
     * There is a role talent
     */
    nicknameInstagram?: string;
    /**
     * There is a role talent
     */
    headShotImg?: string;
    /**
     * There is a role talent
     */
    bodyShotImg?: string;
    /**
     * There is a role talent
     */
    birthDate?: string;
    /**
     * There is a role talent
     */
    height?: number;
    /**
     * There is a role talent
     */
    dressSize?: string;
    /**
     * There is a role talent
     */
    shoeSize?: string;
    /**
     * There is a role talent
     */
    hairColor?: string;
    eyeColor?: string;
    eventPreferences: UserEventPreferencesResponseDTO;
    /**
     * Does the role have a talent
     */
    popularity?: Array<UserPopularityResponseDTO>;
    likesCount: number;
    bookingsCompleteCount: number;
    bookingsCancelCount: number;
    isFeatured: boolean;
    isLike?: boolean;
    isRecommended?: boolean;
    boostTopDate: string;
    boostCity?: (string | CityPopulateResponseDTO);
    boostTopDateUpdatedAt?: string;
    profileUpdatedAt?: string;
    lastActiveBooking?: string;
    createBookingCount?: number;
    documents?: UserDocumentsResponseDTO;
    createdAt: string;
    updatedAt: string;
    additionalFeatures: UserAdditionalFeaturesDTO;
    notifications: UserNotificationsResponseDTO;
    stripeAccountStatus?: AdminProfileResponseDTO.stripeAccountStatus;
    stripeAccountDisabledReason?: string;
    paypalEmail?: string;
    subscription: UserMyProfileSubscriptionResponseDTO;
    balance: number;
    spentSum: number;
    earnedSum: number;
    inviteCount: number;
    isStart: boolean;
    isEnableScreenshots: boolean;
    usingDesign?: AdminProfileResponseDTO.usingDesign;
    isImport?: boolean;
    referrerCode: string;
    referralCode: string;
    /**
     * There are users with the admin role
     */
    adminRights?: UserAdminRightsResponseDTO;
    ambassador: UserAmbassadorDTO;
    /**
     * Number of invited clients
     */
    invitedClientCount: number;
    /**
     * Number of invited talents
     */
    invitedTalentCount: number;
    /**
     * Number of invited payment membership
     */
    invitedMembershipCount: number;
    /**
     * Number of invited active membership
     */
    invitedActiveMembershipCount: number;
    lastLogin: string;
    referrer?: UserListResponseDTO;
    recommended?: Array<string>;
    stripeCustomerId?: string;
    stripeAccountId?: string;
    isHide?: boolean;
    transactionStats: UserTransactionStatsResponseDTO;
};

export namespace AdminProfileResponseDTO {

    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
    }

    export enum role {
        CLIENT = 'client',
        TALENT = 'talent',
        ADMIN = 'admin',
    }

    export enum status {
        PENDING = 'pending',
        JUNK = 'junk',
        APPROVED = 'approved',
        DECLINED = 'declined',
        BLOCKED = 'blocked',
        DEACTIVATED = 'deactivated',
        DELETED = 'deleted',
    }

    export enum stripeAccountStatus {
        INACTIVE = 'inactive',
        ADDITIONAL_INFO = 'additionalInfo',
        PENDING = 'pending',
        ACTIVE = 'active',
        PAUSED = 'paused',
        DISABLED = 'disabled',
    }

    export enum usingDesign {
        NEW = 'new',
        D1 = 'd1',
    }


}

