/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateUpdateBookingInstructionBaseDTO } from './CreateUpdateBookingInstructionBaseDTO';

export type CreateUpdateBookingInstructionDTO = {
    type: CreateUpdateBookingInstructionDTO.type;
    travel: CreateUpdateBookingInstructionBaseDTO;
    event: CreateUpdateBookingInstructionBaseDTO;
};

export namespace CreateUpdateBookingInstructionDTO {

    export enum type {
        TALENT = 'talent',
        CLIENT = 'client',
        PARTICIPANT = 'participant',
    }


}

