/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateLuxuryServiceRequestDTO } from '../models/CreateLuxuryServiceRequestDTO';
import type { LuxuryServiceRequestResponseDTO } from '../models/LuxuryServiceRequestResponseDTO';
import type { PageLuxuryServiceRequestListResponseDTO } from '../models/PageLuxuryServiceRequestListResponseDTO';
import type { UpdateLuxuryServiceRequestDTO } from '../models/UpdateLuxuryServiceRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LuxuryServiceRequestService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * create Luxury Service Request
     * @returns LuxuryServiceRequestResponseDTO Luxury Service Request Data
     * @throws ApiError
     */
    public createLuxuryServiceRequest({
        requestBody,
    }: {
        requestBody: CreateLuxuryServiceRequestDTO,
    }): CancelablePromise<LuxuryServiceRequestResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/luxury-service-request',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The luxury service was not found.`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Cancel Luxury Service Request
     * @returns any
     * @throws ApiError
     */
    public cancelLuxuryServiceRequest({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/luxury-service-request/{id}/cancel',
            path: {
                'id': id,
            },
            errors: {
                400: `The luxury service was not found.`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Update luxury service request by Admin
     * @returns LuxuryServiceRequestResponseDTO Luxury Service Request Data
     * @throws ApiError
     */
    public updateLuxuryServiceRequest({
        id,
        requestBody,
    }: {
        /**
         * Id
         */
        id: string,
        requestBody: UpdateLuxuryServiceRequestDTO,
    }): CancelablePromise<LuxuryServiceRequestResponseDTO> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/luxury-service-request/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or luxuryService.changeRequestStatus can execute the request.`,
            },
        });
    }

    /**
     * Luxury Service Request Delete
     * @returns any Luxury Service Deleted
     * @throws ApiError
     */
    public deleteLuxuryServiceRequest({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/luxury-service-request/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                403: `Only an admin with the right isSuperAdmin or isDelete can execute the request.`,
                404: `Luxury Service not found`,
            },
        });
    }

    /**
     * Luxury Service Request
     * @returns LuxuryServiceRequestResponseDTO Luxury Service Request Data
     * @throws ApiError
     */
    public getLuxuryServiceRequest({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<LuxuryServiceRequestResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/luxury-service-request/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                404: `Luxury Service Request not found`,
            },
        });
    }

    /**
     * Luxury Service Request List
     * @returns PageLuxuryServiceRequestListResponseDTO List of luxury service request
     * @throws ApiError
     */
    public getLuxuryServiceRequestList({
        search,
        status,
        user,
        ownerCity,
        luxuryService,
        city,
        createdAtFrom,
        createdAtTo,
        page = 1,
        limit = 10,
        pageFilter,
        isCount,
    }: {
        /**
         * Used to filter data by name, from, to
         */
        search?: string,
        status?: 'active' | 'canceled' | 'done',
        /**
         * Send the _id. Can be used by the admin
         */
        user?: string,
        /**
         * Send the _id.
         */
        ownerCity?: string,
        /**
         * Send the _id.
         */
        luxuryService?: string,
        /**
         * Send the _id.
         */
        city?: string,
        createdAtFrom?: string,
        createdAtTo?: string,
        page?: number,
        /**
         * If 0 is specified, we will return the data without a limit
         */
        limit?: number,
        /**
         * Used to filter the received data. We get data where the creation date is less than the specified date. If possible, use this to get the following data instead of the "page" parameter as it works faster.
         */
        pageFilter?: string,
        /**
         * If it actively returns the quantity.
         */
        isCount?: boolean,
    }): CancelablePromise<PageLuxuryServiceRequestListResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/luxury-service-request/list',
            query: {
                'search': search,
                'status': status,
                'user': user,
                'ownerCity': ownerCity,
                'luxuryService': luxuryService,
                'city': city,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'page': page,
                'limit': limit,
                'pageFilter': pageFilter,
                'isCount': isCount,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Luxury Service Request Count
     * @returns number Luxury Service Request Count
     * @throws ApiError
     */
    public getLuxuryServiceRequestCount({
        search,
        status,
        user,
        ownerCity,
        luxuryService,
        city,
        createdAtFrom,
        createdAtTo,
    }: {
        /**
         * Used to filter data by name, from, to
         */
        search?: string,
        status?: 'active' | 'canceled' | 'done',
        /**
         * Send the _id. Can be used by the admin
         */
        user?: string,
        /**
         * Send the _id.
         */
        ownerCity?: string,
        /**
         * Send the _id.
         */
        luxuryService?: string,
        /**
         * Send the _id.
         */
        city?: string,
        createdAtFrom?: string,
        createdAtTo?: string,
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/luxury-service-request/count',
            query: {
                'search': search,
                'status': status,
                'user': user,
                'ownerCity': ownerCity,
                'luxuryService': luxuryService,
                'city': city,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

}
