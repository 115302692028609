/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddBookingChatMessageDTO } from '../models/AddBookingChatMessageDTO';
import type { BookingChatRoomMessageResponseDTO } from '../models/BookingChatRoomMessageResponseDTO';
import type { BookingChatRoomResponseDTO } from '../models/BookingChatRoomResponseDTO';
import type { BookingChatUploadFileDTO } from '../models/BookingChatUploadFileDTO';
import type { BookingChatUploadFileResponseDTO } from '../models/BookingChatUploadFileResponseDTO';
import type { PageBookingChatRoomMessageResponseDTO } from '../models/PageBookingChatRoomMessageResponseDTO';
import type { PageBookingChatRoomResponseDTO } from '../models/PageBookingChatRoomResponseDTO';
import type { ReadBookingChatMessageDTO } from '../models/ReadBookingChatMessageDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BookingChatService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create message
     * @returns BookingChatRoomMessageResponseDTO Created message
     * @throws ApiError
     */
    public bookingChatCreateMessage({
        requestBody,
    }: {
        requestBody: AddBookingChatMessageDTO,
    }): CancelablePromise<BookingChatRoomMessageResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/booking/chat/create-message',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Incorrect data`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Booking Uploading a file for the chat
     * @returns BookingChatUploadFileResponseDTO Data for creating a message with a file
     * @throws ApiError
     */
    public bookingChatUploadFile({
        formData,
    }: {
        formData: BookingChatUploadFileDTO,
    }): CancelablePromise<BookingChatUploadFileResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/booking/chat/file',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Incorrect data`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Set notification status
     * @returns any
     * @throws ApiError
     */
    public setNotificationsStatus({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/booking/chat/{id}/notification-status',
            path: {
                'id': id,
            },
            errors: {
                400: `Incorrect data`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Set the read status
     * @returns string Read messages id
     * @throws ApiError
     */
    public bookingChatReadMessages({
        requestBody,
    }: {
        requestBody: ReadBookingChatMessageDTO,
    }): CancelablePromise<Array<string>> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/booking/chat/read-messages',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Incorrect data`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Booking chat list
     * @returns PageBookingChatRoomResponseDTO Booking chat list
     * @throws ApiError
     */
    public getBookingChatRoomList({
        isArchived,
        createdAtFrom,
        createdAtTo,
        page = 1,
        limit = 10,
        pageFilter,
        isCount,
    }: {
        isArchived?: boolean,
        createdAtFrom?: string,
        createdAtTo?: string,
        page?: number,
        /**
         * If 0 is specified, we will return the data without a limit
         */
        limit?: number,
        /**
         * Used to filter the received data. We get data where the last activity date is less than the specified date. If possible, use this to get the following data instead of the "page" parameter as it works faster.
         */
        pageFilter?: string,
        /**
         * If it actively returns the quantity.
         */
        isCount?: boolean,
    }): CancelablePromise<PageBookingChatRoomResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/booking/chat/room/list',
            query: {
                'isArchived': isArchived,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
                'page': page,
                'limit': limit,
                'pageFilter': pageFilter,
                'isCount': isCount,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Booking Chat Room Count
     * @returns number Booking Chat Room Count
     * @throws ApiError
     */
    public getBookingChatRoomCount({
        isArchived,
        createdAtFrom,
        createdAtTo,
    }: {
        isArchived?: boolean,
        createdAtFrom?: string,
        createdAtTo?: string,
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/booking/chat/room/count',
            query: {
                'isArchived': isArchived,
                'createdAtFrom': createdAtFrom,
                'createdAtTo': createdAtTo,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Booking Chat by booking id
     * @returns BookingChatRoomResponseDTO Booking Chat
     * @throws ApiError
     */
    public getBookingRoom({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<BookingChatRoomResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/booking/chat/room/booking/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Booking Chat
     * @returns BookingChatRoomResponseDTO Booking Chat
     * @throws ApiError
     */
    public getBookingChatRoom({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<BookingChatRoomResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/booking/chat/room/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Invalid authorization data`,
                404: `Not found`,
            },
        });
    }

    /**
     * Booking chat message list
     * @returns PageBookingChatRoomMessageResponseDTO Booking chat message list
     * @throws ApiError
     */
    public getBookingChatRoomMessageList({
        room,
        limit = 10,
        pageFilter,
    }: {
        /**
         * Send the _id
         */
        room: string,
        /**
         * If 0 is specified, we will return the data without a limit
         */
        limit?: number,
        /**
         * Used to filter the received data. We get data where the creation date is less than the specified date. If possible, use this to get the following data instead of the "page" parameter as it works faster.
         */
        pageFilter?: string,
    }): CancelablePromise<PageBookingChatRoomMessageResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/booking/chat/room/message/list',
            query: {
                'limit': limit,
                'pageFilter': pageFilter,
                'room': room,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

}
