/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BookingInstructionBaseResponseDTO } from './BookingInstructionBaseResponseDTO';

export type BookingInstructionResponseDTO = {
    _id: string;
    type: BookingInstructionResponseDTO.type;
    travel: BookingInstructionBaseResponseDTO;
    event: BookingInstructionBaseResponseDTO;
};

export namespace BookingInstructionResponseDTO {

    export enum type {
        TALENT = 'talent',
        CLIENT = 'client',
        PARTICIPANT = 'participant',
    }


}

