/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CityPopulateResponseDTO } from './CityPopulateResponseDTO';
import type { UserAdditionalFeaturesDTO } from './UserAdditionalFeaturesDTO';
import type { UserAdminRightsResponseDTO } from './UserAdminRightsResponseDTO';
import type { UserDocumentsResponseDTO } from './UserDocumentsResponseDTO';
import type { UserMyProfileSubscriptionResponseDTO } from './UserMyProfileSubscriptionResponseDTO';

export type UserInfoResponseDTO = {
    _id: string;
    phone: string;
    email: string;
    firstName: string;
    lastName: string;
    legalName: string;
    city: CityPopulateResponseDTO;
    avatar: string;
    gender: UserInfoResponseDTO.gender;
    role: UserInfoResponseDTO.role;
    status: UserInfoResponseDTO.status;
    /**
     * There is a role talent
     */
    headShotImg?: string;
    /**
     * There is a role talent
     */
    bodyShotImg?: string;
    documents?: UserDocumentsResponseDTO;
    createdAt: string;
    updatedAt: string;
    additionalFeatures: UserAdditionalFeaturesDTO;
    stripeAccountStatus?: UserInfoResponseDTO.stripeAccountStatus;
    stripeAccountDisabledReason?: string;
    paypalEmail?: string;
    subscription: UserMyProfileSubscriptionResponseDTO;
    balance: number;
    spentSum: number;
    earnedSum: number;
    inviteCount: number;
    isStart: boolean;
    isEnableScreenshots: boolean;
    usingDesign?: UserInfoResponseDTO.usingDesign;
    isImport?: boolean;
    referralCode: string;
    /**
     * There are users with the admin role
     */
    adminRights?: UserAdminRightsResponseDTO;
};

export namespace UserInfoResponseDTO {

    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
    }

    export enum role {
        CLIENT = 'client',
        TALENT = 'talent',
        ADMIN = 'admin',
    }

    export enum status {
        PENDING = 'pending',
        JUNK = 'junk',
        APPROVED = 'approved',
        DECLINED = 'declined',
        BLOCKED = 'blocked',
        DEACTIVATED = 'deactivated',
        DELETED = 'deleted',
    }

    export enum stripeAccountStatus {
        INACTIVE = 'inactive',
        ADDITIONAL_INFO = 'additionalInfo',
        PENDING = 'pending',
        ACTIVE = 'active',
        PAUSED = 'paused',
        DISABLED = 'disabled',
    }

    export enum usingDesign {
        NEW = 'new',
        D1 = 'd1',
    }


}

