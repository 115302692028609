/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckExistEmailDTO } from '../models/CheckExistEmailDTO';
import type { CheckExistPhoneDTO } from '../models/CheckExistPhoneDTO';
import type { ExistStatusDTO } from '../models/ExistStatusDTO';
import type { ForgotPasswordDTO } from '../models/ForgotPasswordDTO';
import type { RefreshTokenResponseDTO } from '../models/RefreshTokenResponseDTO';
import type { ResetPasswordDTO } from '../models/ResetPasswordDTO';
import type { SendCodeResponseDTO } from '../models/SendCodeResponseDTO';
import type { SendEmailCodeDTO } from '../models/SendEmailCodeDTO';
import type { SendPhoneCodeDTO } from '../models/SendPhoneCodeDTO';
import type { SignInEmailCodeDTO } from '../models/SignInEmailCodeDTO';
import type { SignInEmailPasswordDTO } from '../models/SignInEmailPasswordDTO';
import type { SignInPhoneDTO } from '../models/SignInPhoneDTO';
import type { SignInResponseDTO } from '../models/SignInResponseDTO';
import type { SignupDTO } from '../models/SignupDTO';
import type { UserInfoResponseDTO } from '../models/UserInfoResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Check exist email
     * @returns ExistStatusDTO If true, then the email is busy
     * @throws ApiError
     */
    public checkExistEmail({
        requestBody,
    }: {
        requestBody: CheckExistEmailDTO,
    }): CancelablePromise<ExistStatusDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/check-exist-email',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Check exist phone
     * @returns ExistStatusDTO If true, then the phone is busy
     * @throws ApiError
     */
    public checkExistPhone({
        requestBody,
    }: {
        requestBody: CheckExistPhoneDTO,
    }): CancelablePromise<ExistStatusDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/check-exist-phone',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Send a confirmation code to phone
     * @returns SendCodeResponseDTO We will return the lifetime of the authorization code and status isRegistration.
     * @throws ApiError
     */
    public sendPhoneCode({
        requestBody,
    }: {
        requestBody: SendPhoneCodeDTO,
    }): CancelablePromise<SendCodeResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/send-phone-code',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Exceeded the limit of requests from 1 ip address. The user has been blocked or deleted and cannot log in.`,
                404: `There is no user with this phone number.`,
                409: `The phone number is busy.`,
            },
        });
    }

    /**
     * Check signup phone code
     * @returns any
     * @throws ApiError
     */
    public checkSignupPhoneCode({
        requestBody,
    }: {
        requestBody: SignInPhoneDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/check-signup-phone-code',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The limit of attempts to enter the code has been reached, please get a new code.`,
            },
        });
    }

    /**
     * Send a confirmation code to email
     * @returns SendCodeResponseDTO We will return the lifetime of the authorization code and status isRegistration.
     * @throws ApiError
     */
    public sendEmailCode({
        requestBody,
    }: {
        requestBody: SendEmailCodeDTO,
    }): CancelablePromise<SendCodeResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/send-email-code',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Exceeded the limit of requests from 1 ip address. The user has been blocked or deleted and cannot log in.`,
                404: `There is no user with this email.`,
                409: `The email is busy.`,
            },
        });
    }

    /**
     * Check signup email code
     * @returns any
     * @throws ApiError
     */
    public checkSignupEmailCode({
        requestBody,
    }: {
        requestBody: SignInEmailCodeDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/check-signup-email-code',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The limit of attempts to enter the code has been reached, please get a new code.`,
            },
        });
    }

    /**
     * Signup
     * @returns SignInResponseDTO Token lifetime, access and refresh tokens
     * @throws ApiError
     */
    public signup({
        requestBody,
    }: {
        requestBody: SignupDTO,
    }): CancelablePromise<SignInResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/signup',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Referrer code not valid`,
                409: `Email or phone number is busy`,
            },
        });
    }

    /**
     * Authorization by phone and code
     * @returns SignInResponseDTO Token lifetime, access and refresh tokens
     * @throws ApiError
     */
    public signInPhone({
        requestBody,
    }: {
        requestBody: SignInPhoneDTO,
    }): CancelablePromise<SignInResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/signin-phone',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The limit of attempts to enter the code has been reached, please get a new code. The user has been blocked or deleted and cannot log in.`,
                404: `There is no user with this phone.`,
            },
        });
    }

    /**
     * Authorization by email and code
     * @returns SignInResponseDTO Token lifetime, access and refresh tokens
     * @throws ApiError
     */
    public signInEmailCode({
        requestBody,
    }: {
        requestBody: SignInEmailCodeDTO,
    }): CancelablePromise<SignInResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/signin-email-code',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `The limit of attempts to enter the code has been reached, please get a new code. The user has been blocked or deleted and cannot log in.`,
                404: `There is no user with this email.`,
            },
        });
    }

    /**
     * Authorization by email and password
     * @returns SignInResponseDTO Token lifetime, access and refresh tokens
     * @throws ApiError
     */
    public signInEmailPassword({
        requestBody,
    }: {
        requestBody: SignInEmailPasswordDTO,
    }): CancelablePromise<SignInResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/signin-email-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid data (user not found). The user has been blocked or deleted and cannot log in.`,
            },
        });
    }

    /**
     * Authorized by firebase idToken
     * @returns SignInResponseDTO Token lifetime, access and refresh tokens
     * @throws ApiError
     */
    public signinFirebase(): CancelablePromise<SignInResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/signin-firebase',
        });
    }

    /**
     * Forgot password
     * @returns any The link to reset the password has been sent to the email
     * @throws ApiError
     */
    public forgotPassword({
        requestBody,
    }: {
        requestBody: ForgotPasswordDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/forgot-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `User not found. User role not admin. The user has been blocked or deleted.`,
            },
        });
    }

    /**
     * Reset password
     * @returns void
     * @throws ApiError
     */
    public resetPassword({
        requestBody,
    }: {
        requestBody: ResetPasswordDTO,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/reset-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid data, the password reset token lifetime has expired, the user has not been found`,
            },
        });
    }

    /**
     * Update Token
     * Use refreshToken to authorize this request
     * @returns RefreshTokenResponseDTO Access token
     * @throws ApiError
     */
    public refreshTokens(): CancelablePromise<RefreshTokenResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/auth/refresh',
            errors: {
                401: `Invalid authorization data`,
                404: `User not found`,
            },
        });
    }

    /**
     * Authorized user data
     * @returns UserInfoResponseDTO Authorized user data
     * @throws ApiError
     */
    public getUserInfo(): CancelablePromise<UserInfoResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/auth/user-info',
            errors: {
                401: `Invalid authorization data`,
                404: `User not found`,
            },
        });
    }

}
