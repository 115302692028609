/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ChangeAdminRightsDTO } from './ChangeAdminRightsDTO';

export type CreateAdminUserDTO = {
    phone?: string;
    email: string;
    firstName: string;
    lastName: string;
    gender: CreateAdminUserDTO.gender;
    /**
     * Send the _id
     */
    city: string;
    password: string;
    adminRights: ChangeAdminRightsDTO;
};

export namespace CreateAdminUserDTO {

    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
    }


}

