/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserVerificationDataResponseDTO = {
    status: UserVerificationDataResponseDTO.status;
    inquiryId: string;
    accountId: string;
    isViewAdmin?: boolean;
    createdAt: string;
};

export namespace UserVerificationDataResponseDTO {

    export enum status {
        PENDING = 'pending',
        APPROVED = 'approved',
        REVIEW = 'review',
        DECLINED = 'declined',
    }


}

