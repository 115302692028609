/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CountryResponseDTO = {
    _id: string;
    name: string;
    flag: string;
    code: string;
    dialCode: string;
    region: CountryResponseDTO.region;
    /**
     * Available for admin
     */
    status?: CountryResponseDTO.status;
    /**
     * Available for admin
     */
    createdAt?: string;
    /**
     * Available for admin
     */
    updatedAt?: string;
};

export namespace CountryResponseDTO {

    export enum region {
        USA = 'usa',
        EUROPE = 'europe',
        OTHER = 'other',
    }

    /**
     * Available for admin
     */
    export enum status {
        ACTIVE = 'active',
        HIDDEN = 'hidden',
    }


}

