/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaymentClientSecretResponseDTO } from '../models/PaymentClientSecretResponseDTO';
import type { PaymentCreateOrUpdateSubscriptionDTO } from '../models/PaymentCreateOrUpdateSubscriptionDTO';
import type { PaymentSubscriptionPriceResponseDTO } from '../models/PaymentSubscriptionPriceResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PaymentSubscriptionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create or update subscription
     * @returns PaymentClientSecretResponseDTO If the user already had a subscription, it will be updated and the payment amount will also take into account the amount of the subscription already paid. For example, I bought a subscription for $ 20, then decided to change the subscription and took a subscription for $ 99. In this case, you will need to pay $79. Also, if the user takes a subscription with a lower price, he will not need to pay for it until the funds from the previous subscription are spent.
     * @throws ApiError
     */
    public createOrUpdateSubscription({
        requestBody,
    }: {
        requestBody: PaymentCreateOrUpdateSubscriptionDTO,
    }): CancelablePromise<PaymentClientSecretResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/subscription/create-or-update',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Not price or stripe subscription error.`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Cancel subscription
     * @returns any
     * @throws ApiError
     */
    public cancelSubscription(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/subscription/cancel',
            errors: {
                400: `Not user subscription or stripe subscription error.`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Un cancel subscription
     * @returns any
     * @throws ApiError
     */
    public unCancelSubscription(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/payment/subscription/un-cancel',
            errors: {
                400: `Not user subscription or stripe subscription error.`,
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * List of subscription prices
     * @returns PaymentSubscriptionPriceResponseDTO List of subscription prices
     * @throws ApiError
     */
    public getSubscriptionPriceList(): CancelablePromise<Array<PaymentSubscriptionPriceResponseDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/payment/subscription/price/list',
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

}
