/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SystemMessageListResponseDTO = {
    _id: string;
    userRole: SystemMessageListResponseDTO.userRole;
    segmentation: SystemMessageListResponseDTO.segmentation;
    title: string;
    text: string;
    status: SystemMessageListResponseDTO.status;
    image: string;
    startDate: string;
    endDate: string;
};

export namespace SystemMessageListResponseDTO {

    export enum userRole {
        CLIENT = 'client',
        TALENT = 'talent',
        ADMIN = 'admin',
    }

    export enum segmentation {
        ALL = 'all',
        NEW = 'new',
        NOT_ACTIVE = 'notActive',
        NO_BOOKING = 'noBooking',
        ACTIVE = 'active',
        FREE = 'Free',
        ELITE = 'Elite',
        PREMIUM = 'Premium',
        UPDATED = 'updated',
        BOOSTED = 'boosted',
    }

    export enum status {
        ACTIVE = 'active',
        HIDDEN = 'hidden',
    }


}

