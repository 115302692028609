/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ActionLoggerBookingResponseDTO } from './ActionLoggerBookingResponseDTO';
import type { ActionLoggerUserResponseDTO } from './ActionLoggerUserResponseDTO';

export type ActionLoggerResponseDTO = {
    _id: string;
    user?: (string | ActionLoggerUserResponseDTO);
    resourceType: ActionLoggerResponseDTO.resourceType;
    resource: (ActionLoggerUserResponseDTO | ActionLoggerBookingResponseDTO);
    title: string;
    description: string;
    metadata?: Record<string, any>;
    createdAt: string;
    updatedAt: string;
};

export namespace ActionLoggerResponseDTO {

    export enum resourceType {
        USER = 'User',
        BOOKING = 'Booking',
    }


}

