/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BookingUserResponseDTO } from './BookingUserResponseDTO';
import type { BookingVenueResponseDTO } from './BookingVenueResponseDTO';
import type { CityPopulateResponseDTO } from './CityPopulateResponseDTO';
import type { UserListResponseDTO } from './UserListResponseDTO';

export type BookingInviteListResponseDTO = {
    _id: string;
    /**
     * When Booking by id is received, there will be an object with data instead of a string.Also, when you receive the Booking list for the admin role, there will be an object with data.
     */
    owner: (string | UserListResponseDTO);
    name: string;
    /**
     * There is if "type = travel"
     */
    city?: CityPopulateResponseDTO;
    eventType: BookingInviteListResponseDTO.eventType;
    type: BookingInviteListResponseDTO.type;
    visibility: BookingInviteListResponseDTO.visibility;
    venue: BookingVenueResponseDTO;
    /**
     * There is if "type = travel".
     */
    cityTravel?: string;
    isInvite: boolean;
    status: BookingInviteListResponseDTO.status;
    startDate: string;
    endDate: string;
    createdAt: string;
    updatedAt: string;
    invited: BookingUserResponseDTO;
};

export namespace BookingInviteListResponseDTO {

    export enum eventType {
        ENTERTAINMENT = 'entertainment',
        MATCHMAKING = 'matchmaking',
    }

    export enum type {
        EVENT = 'event',
        TRAVEL = 'travel',
    }

    export enum visibility {
        PUBLIC = 'public',
        PRIVATE = 'private',
    }

    export enum status {
        ACTIVE = 'active',
        CANCELED = 'canceled',
        DONE = 'done',
        PAID = 'paid',
    }


}

