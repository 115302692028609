/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CountryPopulateResponseDTO } from './CountryPopulateResponseDTO';
import type { VenueMinDataResponseDTO } from './VenueMinDataResponseDTO';

export type CityResponseDTO = {
    _id: string;
    country: CountryPopulateResponseDTO;
    name: string;
    image: string;
    timezone: string;
    /**
     * Available for admin
     */
    status?: CityResponseDTO.status;
    /**
     * Available for admin
     */
    venueDefault?: VenueMinDataResponseDTO;
    /**
     * Available for admin
     */
    createdAt?: string;
    /**
     * Available for admin
     */
    updatedAt?: string;
};

export namespace CityResponseDTO {

    /**
     * Available for admin
     */
    export enum status {
        ACTIVE = 'active',
        HIDDEN = 'hidden',
    }


}

