/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProductDTO } from '../models/CreateProductDTO';
import type { PageProductResponseDTO } from '../models/PageProductResponseDTO';
import type { ProductResponseDTO } from '../models/ProductResponseDTO';
import type { UpdateProductDTO } from '../models/UpdateProductDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Product creation
     * @returns any Product successfully created
     * @throws ApiError
     */
    public createProduct({
        requestBody,
    }: {
        requestBody: CreateProductDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/product',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin or product.addEdit can execute the request.`,
            },
        });
    }

    /**
     * Update Product
     * @returns void
     * @throws ApiError
     */
    public updateProduct({
        id,
        requestBody,
    }: {
        /**
         * Id
         */
        id: string,
        requestBody: UpdateProductDTO,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/product/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin or product.addEdit can execute the request.`,
                404: `Product not found`,
            },
        });
    }

    /**
     * Delete Product
     * @returns void
     * @throws ApiError
     */
    public deleteProduct({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/product/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin or isDelete can execute the request.`,
                404: `Product not found`,
            },
        });
    }

    /**
     * Get Product
     * @returns ProductResponseDTO Product
     * @throws ApiError
     */
    public getProduct({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<ProductResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/product/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Wrong authorization data`,
                404: `Product not found`,
            },
        });
    }

    /**
     * Product list
     * @returns PageProductResponseDTO List of products
     * @throws ApiError
     */
    public getProductList({
        search,
        status,
        userRole,
        type,
        page = 1,
        limit = 10,
        isCount,
    }: {
        /**
         * Used to filter by name
         */
        search?: string,
        /**
         * Can be used by the admin.
         */
        status?: 'active' | 'hidden',
        /**
         * Can be used by the admin.
         */
        userRole?: 'client' | 'talent' | 'admin',
        type?: 'invite' | 'boostFeed',
        page?: number,
        /**
         * If 0 is specified, we will return the data without a limit
         */
        limit?: number,
        /**
         * If it actively returns the quantity.
         */
        isCount?: boolean,
    }): CancelablePromise<PageProductResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/product/list',
            query: {
                'search': search,
                'status': status,
                'userRole': userRole,
                'type': type,
                'page': page,
                'limit': limit,
                'isCount': isCount,
            },
            errors: {
                401: `Wrong authorization data`,
            },
        });
    }

    /**
     * Product Count
     * @returns number Product Count
     * @throws ApiError
     */
    public getProductCount({
        search,
        status,
        userRole,
        type,
    }: {
        /**
         * Used to filter by name
         */
        search?: string,
        /**
         * Can be used by the admin.
         */
        status?: 'active' | 'hidden',
        /**
         * Can be used by the admin.
         */
        userRole?: 'client' | 'talent' | 'admin',
        type?: 'invite' | 'boostFeed',
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/product/count',
            query: {
                'search': search,
                'status': status,
                'userRole': userRole,
                'type': type,
            },
            errors: {
                401: `Wrong authorization data`,
            },
        });
    }

}
