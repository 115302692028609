import {
  AdminUserListResponseDTO,
  TransactionResponseDTO,
  CreateCommonDataDTO,
  VenueResponseDTO,
  BookingListResponseDTO,
  CityResponseDTO,
  CountryResponseDTO,
  CreateCharityDTO,
  AdminUserVerificationDTO,
  AdminCreateTransactionDTO,
  UpdateStatusPayoutPaypalDTO,
  UserInfoResponseDTO,
  CreateLuxuryServiceDTO,
  LuxuryServiceRequestListResponseDTO,
  UpdateLuxuryServiceRequestDTO,
  CreatePayoutDTO,
  SystemMessageResponseDTO,
  AdminIncludeStatusBookingDTO,
  ChangeUserBoostTopDTO,
  AdminChangeUserDTO,
  ProfileMyResponseDTO,
  ProductResponseDTO,
} from "src/api/openapi";
import { PopoverItem, SelectItem } from "../types/ui";
import { camelCaseToTitle } from "../utils/fn";

export const allItem: SelectItem<string> = { value: "", label: "All" };

export const selectAllItem = <T extends SelectItem>(
  items: T[],
  isAll = false
): T[] => {
  if (isAll) return [allItem as T, ...items];
  return items;
};

export const commonDataSelectStatuses = (isAll = false): SelectItem[] => {
  const items = Object.values(CreateCommonDataDTO.status).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const luxuryServicesRequsetStatuses = (isAll = false): SelectItem[] => {
  const items = Object.values(UpdateLuxuryServiceRequestDTO.status).map(
    (item) => {
      return { value: item, label: camelCaseToTitle(item) };
    }
  );
  return selectAllItem(items, isAll);
};

export const userSelectStatuses = (isAll = false): SelectItem[] => {
  const items = Object.values(AdminUserListResponseDTO.status).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const bookingStatuses = (
  isAll = false
): SelectItem<BookingListResponseDTO.status>[] => {
  const items = Object.values(BookingListResponseDTO.status).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const bookingInvited = (
  isAll = false
) => {
  const items = [
    {
      value: "true",
      label: 'Invitations',
    },
    {
      value: "false",
      label: 'Bookings',
    },
  ];
  return selectAllItem(items, isAll);
};

export const bookingTypes = (
  isAll = false
): SelectItem<BookingListResponseDTO.type>[] => {
  const items = Object.values(BookingListResponseDTO.type).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const bookingEventTypes = (
  isAll = false
): SelectItem<BookingListResponseDTO.eventType>[] => {
  const items = Object.values(BookingListResponseDTO.eventType).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const userVerificationStatuses = (isAll = false): SelectItem[] => {
  const items = Object.values(AdminUserVerificationDTO.status).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const userSelectRoles = (isAll = false): SelectItem[] => {
  const items = Object.values(AdminUserListResponseDTO.role).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const userSelectStatusesBooking = (isAll = false): PopoverItem[] => {
  const items = Object.values(AdminIncludeStatusBookingDTO.status).map(
    (item) => {
      return { value: item, label: camelCaseToTitle(item) };
    }
  );
  return selectAllItem(items, isAll);
};

export const transactionSelectStatuses = (isAll = false): SelectItem[] => {
  const items = Object.values(TransactionResponseDTO.status).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const transactionSelectStatusesUpdate = (
  isAll = false
): SelectItem[] => {
  const items = Object.values(UpdateStatusPayoutPaypalDTO.status).map(
    (item) => {
      return { value: item, label: camelCaseToTitle(item) };
    }
  );
  return selectAllItem(items, isAll);
};

export const transactionSelectTypesList = (isAll = false): SelectItem[] => {
  const items = Object.values(TransactionResponseDTO.type).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const transactionSelectCategoriesList = (
  isAll = false
): SelectItem[] => {
  const items = Object.values(TransactionResponseDTO.category).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const transactionSelectMovementFundsList = (
  isAll = false
): SelectItem[] => {
  const items = Object.values(TransactionResponseDTO.movementFunds).map(
    (item) => {
      return { value: item, label: camelCaseToTitle(item) };
    }
  );
  return selectAllItem(items, isAll);
};

export const transactionSelectPayoutType = (isAll = false): SelectItem[] => {
  const items = Object.values(TransactionResponseDTO.payoutType).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const transactionSelectTypes = (isAll = false): SelectItem[] => {
  const items = Object.values(AdminCreateTransactionDTO.type).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const transactionSelectCategories = (isAll = false): SelectItem[] => {
  const items = Object.values(AdminCreateTransactionDTO.category).map(
    (item) => {
      return { value: item, label: camelCaseToTitle(item) };
    }
  );
  return selectAllItem(items, isAll);
};

export const transactionSelectMovementFunds = (isAll = false): SelectItem[] => {
  const items = Object.values(AdminCreateTransactionDTO.movementFunds).map(
    (item) => {
      return { value: item, label: camelCaseToTitle(item) };
    }
  );
  return selectAllItem(items, isAll);
};

export const transactionSelectResourceType = (isAll = false): SelectItem[] => {
  const items = Object.values(AdminCreateTransactionDTO.resourceType).map(
    (item) => {
      return { value: item, label: camelCaseToTitle(item) };
    }
  );
  return selectAllItem(items, isAll);
};

// export const VenueSelectTypes = (
//   isAll = false
// ): SelectItem<VenueResponseDTO.type>[] => {
//   const items = Object.values(VenueResponseDTO.type).map((item) => {
//     return { value: item, label: camelCaseToTitle(item) };
//   });
//   return selectAllItem(items, isAll);
// };

export const VenueSelectPriceRating = (
  isAll = false
): SelectItem<VenueResponseDTO.priceRating>[] => {
  const items = Object.values(VenueResponseDTO.priceRating).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const VenueSelectStatuses = (
  isAll = false
): SelectItem<VenueResponseDTO.status>[] => {
  const items = Object.values(VenueResponseDTO.status).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const cityStatuses = (isAll = false): SelectItem[] => {
  const items = Object.values(CityResponseDTO.status).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const payoutTypes = (isAll = false): SelectItem[] => {
  const items = Object.values(CreatePayoutDTO.type).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const partnerCategories = (isAll = false): SelectItem[] => {
  const items = Object.values(CreateLuxuryServiceDTO.category).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const partnerGeneralCategories = (isAll = false): SelectItem[] => {
  const items = Object.values(CreateLuxuryServiceDTO.generalCategory).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const partnerStatuses = (isAll = false): SelectItem[] => {
  const items = Object.values(CreateLuxuryServiceDTO.status).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const requestStatuses = (isAll = false): SelectItem[] => {
  const items = Object.values(LuxuryServiceRequestListResponseDTO.status).map(
    (item) => {
      return { value: item, label: camelCaseToTitle(item) };
    }
  );
  return selectAllItem(items, isAll);
};

export const countryStatuses = (isAll = false): SelectItem[] => {
  const items = Object.values(CountryResponseDTO.status).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const countryRegions = (isAll = false): SelectItem[] => {
  const items = Object.values(CountryResponseDTO.region).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const eventPreferencesTalent = (isAll = false): SelectItem[] => {
  const items = ["entertainment", "matchmaking", "travels"].map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const charityStatuses = (
  isAll = false
): SelectItem<CreateCharityDTO.status>[] => {
  const items = Object.values(CreateCharityDTO.status).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const addTopArray = (isAll = false): SelectItem[] => {
  const items = [
    { label: "Choose boost", value: ChangeUserBoostTopDTO.period._0 },
    { label: "24 hours", value: ChangeUserBoostTopDTO.period._24H },
    { label: "48 hours", value: ChangeUserBoostTopDTO.period._48H },
    { label: "72 hours", value: ChangeUserBoostTopDTO.period._72H },
    { label: "7 days", value: ChangeUserBoostTopDTO.period._7D },
  ];
  return selectAllItem(items, isAll);
};

export const genderArray = (): SelectItem[] => {
  const items = Object.values(AdminChangeUserDTO.gender).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items);
};

export const clientSelectSegmentation = (isAll = false): SelectItem[] => {
  const items = [
    { label: "All", value: SystemMessageResponseDTO.segmentation.ALL },
    {
      label: "New – one week (7 days)",
      value: SystemMessageResponseDTO.segmentation.NEW,
    },
    {
      label: "Not active – no bookings 30 days",
      value: SystemMessageResponseDTO.segmentation.NOT_ACTIVE,
    },
    {
      label: "No bookings – 0 bookings",
      value: SystemMessageResponseDTO.segmentation.NO_BOOKING,
    },
    {
      label: "Active – 1 booking in last 7 days",
      value: SystemMessageResponseDTO.segmentation.ACTIVE,
    },
    { label: "Free", value: SystemMessageResponseDTO.segmentation.FREE },
    { label: "Premium", value: SystemMessageResponseDTO.segmentation.PREMIUM },
    { label: "Elite", value: SystemMessageResponseDTO.segmentation.ELITE },
  ];
  return selectAllItem(items, isAll);
};

export const talentSelectSegmentation = (isAll = false): SelectItem[] => {
  const items = [
    { label: "All", value: SystemMessageResponseDTO.segmentation.ALL },
    {
      label: "New – one week (7 days)",
      value: SystemMessageResponseDTO.segmentation.NEW,
    },
    {
      label: "Updated – photo / video updated in last month",
      value: SystemMessageResponseDTO.segmentation.UPDATED,
    },
    {
      label: "Boosted – had active boost in last month",
      value: SystemMessageResponseDTO.segmentation.BOOSTED,
    },
  ];
  return selectAllItem(items, isAll);
};

export const yearsSelect = (isAll = false): SelectItem[] => {
  const startYear = 2023;
  const currentYear = new Date().getFullYear();
  const years: SelectItem[] = [];
  for (let i = startYear; i <= currentYear; i++) {
    years.push({ label: i.toString(), value: i });
  }
  return selectAllItem(years, isAll);
};

export const subscriptionTitlesSelect = (isAll = false): SelectItem[] => {
  const statuses = ["Free", "Elite", "Premium"];
  const items = statuses.map((item) => {
    return { value: item, label: item };
  });
  return selectAllItem(items, isAll);
};

export const genderSelect = (
  isAll = false
): SelectItem<UserInfoResponseDTO.gender>[] => {
  const items = Object.values(UserInfoResponseDTO.gender).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const designSelect = (
  isAll = false
): SelectItem<ProfileMyResponseDTO.usingDesign>[] => {
  const items = Object.values(ProfileMyResponseDTO.usingDesign).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const productSelectStatuses = (isAll = false): SelectItem[] => {
  const items = Object.values(ProductResponseDTO.status).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const productSelectUserRoles = (isAll = false): SelectItem[] => {
  const items = Object.values(ProductResponseDTO.userRole).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const productSelectUserTypes = (isAll = false): SelectItem[] => {
  const items = Object.values(ProductResponseDTO.type).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};

export const productSelectIntervals = (isAll = false): SelectItem[] => {
  const items = Object.values(ProductResponseDTO.interval).map((item) => {
    return { value: item, label: camelCaseToTitle(item) };
  });
  return selectAllItem(items, isAll);
};