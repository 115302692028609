/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PageActionLoggerResponseDTO } from '../models/PageActionLoggerResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ActionLoggerService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Action Logger List
     * @returns PageActionLoggerResponseDTO Action Logger List
     * @throws ApiError
     */
    public getActionLoggerList({
        userId,
        resourceId,
        resourceType,
        page = 1,
        limit = 10,
        pageFilter,
        isCount,
    }: {
        /**
         * Send the _id
         */
        userId?: string,
        /**
         * Send the _id
         */
        resourceId?: string,
        resourceType?: 'User' | 'Booking',
        page?: number,
        /**
         * If 0 is specified, we will return the data without a limit
         */
        limit?: number,
        /**
         * Used to filter the received data. We get data where the creation date is less than the specified date. If possible, use this to get the following data instead of the "page" parameter as it works faster.
         */
        pageFilter?: string,
        /**
         * If it actively returns the quantity.
         */
        isCount?: boolean,
    }): CancelablePromise<PageActionLoggerResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/action-logger/list',
            query: {
                'userId': userId,
                'resourceId': resourceId,
                'resourceType': resourceType,
                'page': page,
                'limit': limit,
                'pageFilter': pageFilter,
                'isCount': isCount,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Action Logger Count
     * @returns number Action Logger Count
     * @throws ApiError
     */
    public getActionLoggerCount({
        userId,
        resourceId,
        resourceType,
    }: {
        /**
         * Send the _id
         */
        userId?: string,
        /**
         * Send the _id
         */
        resourceId?: string,
        resourceType?: 'User' | 'Booking',
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/action-logger/count',
            query: {
                'userId': userId,
                'resourceId': resourceId,
                'resourceType': resourceType,
            },
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

}
