/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookingInstructionResponseDTO } from '../models/BookingInstructionResponseDTO';
import type { CreateUpdateBookingInstructionDTO } from '../models/CreateUpdateBookingInstructionDTO';
import type { PageBookingInstructionResponseDTO } from '../models/PageBookingInstructionResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BookingInstructionService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Booking instruction creation or update
     * @returns any Booking instruction successfully created or updater
     * @throws ApiError
     */
    public createOrUpdateBookingInstruction({
        requestBody,
    }: {
        requestBody: CreateUpdateBookingInstructionDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/booking-instruction',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
            },
        });
    }

    /**
     * Booking instruction list
     * @returns PageBookingInstructionResponseDTO List of booking instruction
     * @throws ApiError
     */
    public getBookingInstructionsList({
        type,
        page = 1,
        limit = 10,
        isCount,
    }: {
        type?: 'talent' | 'client' | 'participant',
        page?: number,
        /**
         * If 0 is specified, we will return the data without a limit
         */
        limit?: number,
        /**
         * If it actively returns the quantity.
         */
        isCount?: boolean,
    }): CancelablePromise<PageBookingInstructionResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/booking-instruction/list',
            query: {
                'type': type,
                'page': page,
                'limit': limit,
                'isCount': isCount,
            },
            errors: {
                401: `Wrong authorization data`,
                403: `Only the admin can complete this request.`,
            },
        });
    }

    /**
     * Booking instruction Count
     * @returns number Booking instruction Count
     * @throws ApiError
     */
    public getBookingInstructionCount({
        type,
    }: {
        type?: 'talent' | 'client' | 'participant',
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/booking-instruction/count',
            query: {
                'type': type,
            },
            errors: {
                401: `Wrong authorization data`,
                403: `Only the admin can complete this request.`,
            },
        });
    }

    /**
     * Booking instruction type
     * @returns BookingInstructionResponseDTO Booking instruction
     * @throws ApiError
     */
    public getBookingInstructionType({
        type,
    }: {
        type: string,
    }): CancelablePromise<BookingInstructionResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/booking-instruction/{type}',
            path: {
                'type': type,
            },
            errors: {
                401: `Wrong authorization data`,
                403: `Only the admin can complete this request.`,
                404: `Booking instruction not found`,
            },
        });
    }

}
