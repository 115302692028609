/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PayoutDetailsStripeCardResponseDTO = {
    type: PayoutDetailsStripeCardResponseDTO.type;
    name: string;
    country: string;
    currency: string;
    last4: string;
    brand: string;
    expMonth: number;
    expYear: number;
};

export namespace PayoutDetailsStripeCardResponseDTO {

    export enum type {
        STRIPE_CARD = 'stripeCard',
    }


}

