/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CityPopulateResponseDTO } from './CityPopulateResponseDTO';
import type { PickTypeClass } from './PickTypeClass';
import type { UserDocumentsResponseDTO } from './UserDocumentsResponseDTO';
import type { UserEventPreferencesResponseDTO } from './UserEventPreferencesResponseDTO';
import type { UserMyProfileSubscriptionResponseDTO } from './UserMyProfileSubscriptionResponseDTO';

export type AdminUserListResponseDTO = {
    _id: string;
    phone: string;
    email: string;
    firstName: string;
    lastName: string;
    city: CityPopulateResponseDTO;
    avatar: string;
    rating: number;
    role: AdminUserListResponseDTO.role;
    status: AdminUserListResponseDTO.status;
    /**
     * There is a role client
     */
    subscriptionTitle?: string;
    /**
     * There is a role talent
     */
    nicknameInstagram?: string;
    eventPreferences: UserEventPreferencesResponseDTO;
    isFeatured: boolean;
    isLike?: boolean;
    isRecommended?: boolean;
    boostTopDate: string;
    boostCity?: (string | CityPopulateResponseDTO);
    documents?: UserDocumentsResponseDTO;
    createdAt: string;
    updatedAt: string;
    subscription: UserMyProfileSubscriptionResponseDTO;
    balance: number;
    spentSum: number;
    earnedSum: number;
    lastLogin: string;
    isHide?: boolean;
    /**
     * There are users with the admin role
     */
    adminRights?: PickTypeClass;
};

export namespace AdminUserListResponseDTO {

    export enum role {
        CLIENT = 'client',
        TALENT = 'talent',
        ADMIN = 'admin',
    }

    export enum status {
        PENDING = 'pending',
        JUNK = 'junk',
        APPROVED = 'approved',
        DECLINED = 'declined',
        BLOCKED = 'blocked',
        DEACTIVATED = 'deactivated',
        DELETED = 'deleted',
    }


}

