/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePhylloPlatformDTO } from '../models/CreatePhylloPlatformDTO';
import type { PhylloWorkPlatformResponseDTO } from '../models/PhylloWorkPlatformResponseDTO';
import type { UserAccountTokenPhylloResponseDTO } from '../models/UserAccountTokenPhylloResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserAccountService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create Phyllo Sdk Token
     * @returns UserAccountTokenPhylloResponseDTO
     * @throws ApiError
     */
    public createPhylloSdkToken(): CancelablePromise<UserAccountTokenPhylloResponseDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/account/phyllo-sdk-token',
            errors: {
                400: `Phyllo error`,
                401: `Invalid authorization data`,
                403: `You must be a role talent`,
            },
        });
    }

    /**
     * Create Phyllo Work Platforms
     * @returns any
     * @throws ApiError
     */
    public createPhylloWorkPlatforms({
        requestBody,
    }: {
        requestBody: CreatePhylloPlatformDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/account/phyllo-work-platform',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Invalid authorization data`,
                403: `You must be a role admin`,
            },
        });
    }

    /**
     * Phyllo Work Platforms
     * @returns PhylloWorkPlatformResponseDTO
     * @throws ApiError
     */
    public getPhylloWorkPlatforms(): CancelablePromise<Array<PhylloWorkPlatformResponseDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/account/phyllo-work-platform',
            errors: {
                401: `Invalid authorization data`,
            },
        });
    }

    /**
     * Phyllo disconnect account
     * @returns any
     * @throws ApiError
     */
    public phylloDisconnectAccount({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/account/phyllo-disconnect-account/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `User not accountId`,
                401: `Invalid authorization data`,
                403: `You must be a role talent`,
            },
        });
    }

}
