/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AdminUploadW9DTO = {
    file: Blob;
    status: AdminUploadW9DTO.status;
};

export namespace AdminUploadW9DTO {

    export enum status {
        PENDING = 'pending',
        APPROVED = 'approved',
        REVIEW = 'review',
        DECLINED = 'declined',
    }


}

