/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PushNotificationListResponseDTO = {
    _id: string;
    userRole: PushNotificationListResponseDTO.userRole;
    segmentation: PushNotificationListResponseDTO.segmentation;
    title: string;
    text: string;
    sendDate: string;
    sendCount: number;
};

export namespace PushNotificationListResponseDTO {

    export enum userRole {
        CLIENT = 'client',
        TALENT = 'talent',
        ADMIN = 'admin',
    }

    export enum segmentation {
        ALL = 'all',
        NEW = 'new',
        NOT_ACTIVE = 'notActive',
        NO_BOOKING = 'noBooking',
        ACTIVE = 'active',
        FREE = 'Free',
        ELITE = 'Elite',
        PREMIUM = 'Premium',
        UPDATED = 'updated',
        BOOSTED = 'boosted',
    }


}

