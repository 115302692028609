import {
  CountryPopulateResponseDTO,
  UserInfoResponseDTO,
  UserMyProfileSubscriptionResponseDTO,
} from "src/api/openapi";
import Context from "./context";

export const initialUserState: UserInfoResponseDTO = {
  _id: "",
  firstName: "",
  lastName: "",
  avatar: "",
  phone: "",
  email: "",
  city: {
    _id: "",
    country: {
      _id: "",
      name: "",
      flag: "",
      code: "",
      dialCode: "",
      region: CountryPopulateResponseDTO.region.USA,
    },
    name: "",
    timezone: "",
  },
  createdAt: "",
  updatedAt: "",
  legalName: "",
  gender: UserInfoResponseDTO.gender.MALE,
  role: UserInfoResponseDTO.role.ADMIN,
  status: UserInfoResponseDTO.status.PENDING,
  subscription: {
    status: UserMyProfileSubscriptionResponseDTO.status.ACTIVE,
    interval: UserMyProfileSubscriptionResponseDTO.interval.DAY,
    title: "",
    createdAt: 0,
    periodStart: 0,
    periodEnd: 0,
    amount: 0,
  },
  balance: 0,
  spentSum: 0,
  inviteCount: 0,
  earnedSum: 0,
  isStart: false,
  referralCode: "",
  additionalFeatures: {
    commissionPayment: 0,
    isSendInvitationWith0: false,
    isApproveTalentWith0: false,
    isShare: false,
    isAllowEntertainmentPublicBookingOneTalent: false,
    isDisableCancellationPolicy: false,
  },
  isEnableScreenshots: false,
};

const UserContext = new Context(initialUserState);

export default UserContext;
