/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommonDataResponseDTO } from '../models/CommonDataResponseDTO';
import type { CreateCommonDataDTO } from '../models/CreateCommonDataDTO';
import type { PageCommonDataResponseDTO } from '../models/PageCommonDataResponseDTO';
import type { UpdateCommonDataDTO } from '../models/UpdateCommonDataDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EyeColorService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Eye color creation
     * @returns any Eye color successfully created
     * @throws ApiError
     */
    public createEyeColor({
        requestBody,
    }: {
        requestBody: CreateCommonDataDTO,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/eye-color',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
            },
        });
    }

    /**
     * Update eye color name
     * @returns void
     * @throws ApiError
     */
    public updateEyeColor({
        id,
        requestBody,
    }: {
        /**
         * Id
         */
        id: string,
        requestBody: UpdateCommonDataDTO,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/eye-color/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
                404: `Eye color not found`,
            },
        });
    }

    /**
     * Delete eye color
     * @returns void
     * @throws ApiError
     */
    public deleteEyeColor({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/eye-color/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Wrong authorization data`,
                403: `Only an admin with the right isSuperAdmin can execute the request.`,
                404: `Eye color not found`,
            },
        });
    }

    /**
     * Eye color
     * @returns CommonDataResponseDTO Eye color
     * @throws ApiError
     */
    public getEyeColor({
        id,
    }: {
        /**
         * Id
         */
        id: string,
    }): CancelablePromise<CommonDataResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/eye-color/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Eye color not found`,
            },
        });
    }

    /**
     * Eye colors list by user
     * @returns PageCommonDataResponseDTO List of eye colors
     * @throws ApiError
     */
    public getEyeColorsListByUser({
        search,
        status,
        byCity,
        byUserRole,
        page = 1,
        limit = 10,
        isCount,
    }: {
        /**
         * Used to filter data by name
         */
        search?: string,
        /**
         * Can be used by the admin.
         */
        status?: 'active' | 'hidden',
        /**
         * Send the _id.
         */
        byCity?: string,
        /**
         * For list requests by user.
         */
        byUserRole?: 'client' | 'talent' | 'admin',
        page?: number,
        /**
         * If 0 is specified, we will return the data without a limit
         */
        limit?: number,
        /**
         * If it actively returns the quantity.
         */
        isCount?: boolean,
    }): CancelablePromise<PageCommonDataResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/eye-color/list-user',
            query: {
                'search': search,
                'status': status,
                'byCity': byCity,
                'byUserRole': byUserRole,
                'page': page,
                'limit': limit,
                'isCount': isCount,
            },
        });
    }

    /**
     * Eye colors list
     * @returns PageCommonDataResponseDTO List of eye colors
     * @throws ApiError
     */
    public getEyeColorsList({
        search,
        status,
        byCity,
        byUserRole,
        page = 1,
        limit = 10,
        isCount,
    }: {
        /**
         * Used to filter data by name
         */
        search?: string,
        /**
         * Can be used by the admin.
         */
        status?: 'active' | 'hidden',
        /**
         * Send the _id.
         */
        byCity?: string,
        /**
         * For list requests by user.
         */
        byUserRole?: 'client' | 'talent' | 'admin',
        page?: number,
        /**
         * If 0 is specified, we will return the data without a limit
         */
        limit?: number,
        /**
         * If it actively returns the quantity.
         */
        isCount?: boolean,
    }): CancelablePromise<PageCommonDataResponseDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/eye-color/list',
            query: {
                'search': search,
                'status': status,
                'byCity': byCity,
                'byUserRole': byUserRole,
                'page': page,
                'limit': limit,
                'isCount': isCount,
            },
        });
    }

    /**
     * Eye colors Count
     * @returns number Eye colors Count
     * @throws ApiError
     */
    public getEyeColorsCount({
        search,
        status,
        byCity,
        byUserRole,
    }: {
        /**
         * Used to filter data by name
         */
        search?: string,
        /**
         * Can be used by the admin.
         */
        status?: 'active' | 'hidden',
        /**
         * Send the _id.
         */
        byCity?: string,
        /**
         * For list requests by user.
         */
        byUserRole?: 'client' | 'talent' | 'admin',
    }): CancelablePromise<number> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/eye-color/count',
            query: {
                'search': search,
                'status': status,
                'byCity': byCity,
                'byUserRole': byUserRole,
            },
        });
    }

}
