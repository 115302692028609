/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateLuxuryServiceRequestDTO = {
    name: string;
    /**
     * Send the _id.
     */
    luxuryService: string;
    /**
     * It is used to verify the form data.
     */
    luxuryServiceCategory: CreateLuxuryServiceRequestDTO.luxuryServiceCategory;
    /**
     * Send the _id. There is if "luxuryServiceCategory = restaurant"
     */
    city?: string;
    /**
     * There is if "luxuryServiceCategory = restaurant"
     */
    restaurantName?: string;
    /**
     * There is if "luxuryServiceCategory = villa,cars"
     */
    destination?: string;
    /**
     * There is if "luxuryServiceCategory = villa"
     */
    countRooms?: string;
    /**
     * There is if "luxuryServiceCategory = jets,yacht"
     */
    from?: string;
    /**
     * There is if "luxuryServiceCategory = jets,yacht"
     */
    to?: string;
    /**
     * There is if "luxuryServiceCategory = restaurant,jets,yacht,villa"
     */
    countUsers?: string;
    serviceLevel?: string;
    specialRequests: string;
    startDate: string;
    /**
     * There is if "luxuryService.category = villa,jets,yacht,cars"
     */
    endDate?: string;
};

export namespace CreateLuxuryServiceRequestDTO {

    /**
     * It is used to verify the form data.
     */
    export enum luxuryServiceCategory {
        RESTAURANT = 'restaurant',
        VILLA = 'villa',
        JETS = 'jets',
        YACHT = 'yacht',
        CARS = 'cars',
    }


}

