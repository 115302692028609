/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpdateCountryDTO = {
    name?: string;
    flag?: string;
    code?: string;
    dialCode?: string;
    region?: UpdateCountryDTO.region;
    timezone?: string;
    status?: UpdateCountryDTO.status;
};

export namespace UpdateCountryDTO {

    export enum region {
        USA = 'usa',
        EUROPE = 'europe',
        OTHER = 'other',
    }

    export enum status {
        ACTIVE = 'active',
        HIDDEN = 'hidden',
    }


}

